.other-events:link {
    color: var(--link-font-color);
    text-decoration: none;
}
.other-events:hover {
    color: var(--link-font-color-hover);
    text-decoration: none;
}
.other-events:visited {
    color: var(--link-font-color-visted);
    text-decoration: none;
}