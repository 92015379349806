
.imprint-container > h1 {
    margin: 16px 0;
    font-size: var(--font-size-h1-normal);
}

.imprint-entry {
    margin: 10px;
    padding: 18px 45px 18px 18px;
}

.imprint-entry > h2 {
    margin: 8px 0;
}

