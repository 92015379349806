.goals-last-change {
    font-size: var(--font-size-anotations-normal);
    color: #838383;
    text-align: center;
}

.goals-list {
    width: 90%;
    max-width: 600px;
    margin: 24px auto;
    list-style-type: none;
}

.goals-list-entry {
    font-size: var(--font-size-standard-normal);
    margin: 10px;
    padding: 18px 45px 18px 18px;
    border-radius: 10px;
}