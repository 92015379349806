.menu-icon {

}

.menu-burger {
    content:url("../../images/ui/menu_burger_to_x_ani_0.png");
    animation-name: aniShowMenuBurger;
    animation-duration: 0.2s;
}

.menu-back {
    content:url("../../images/ui/menu_burger_to_x_ani_8.png");
    animation-name: aniShowMenuX;
    animation-duration: 0.2s;
}

@keyframes aniShowMenuBurger {
    0%   {content:url("../../images/ui/menu_burger_to_x_ani_8.png");}
    14%  {content:url("../../images/ui/menu_burger_to_x_ani_7.png");}
    28%  {content:url("../../images/ui/menu_burger_to_x_ani_6.png");}
    42%  {content:url("../../images/ui/menu_burger_to_x_ani_5.png");}
    50%  {content:url("../../images/ui/menu_burger_to_x_ani_4.png");}
    62%  {content:url("../../images/ui/menu_burger_to_x_ani_3.png");}
    81%  {content:url("../../images/ui/menu_burger_to_x_ani_2.png");}
    90%  {content:url("../../images/ui/menu_burger_to_x_ani_1.png");}
    100% {content:url("../../images/ui/menu_burger_to_x_ani_0.png");}
}

@keyframes aniShowMenuX {
    0%   {content:url("../../images/ui/menu_burger_to_x_ani_0.png");}
    14%  {content:url("../../images/ui/menu_burger_to_x_ani_1.png");}
    28%  {content:url("../../images/ui/menu_burger_to_x_ani_2.png");}
    42%  {content:url("../../images/ui/menu_burger_to_x_ani_3.png");}
    50%  {content:url("../../images/ui/menu_burger_to_x_ani_4.png");}
    62%  {content:url("../../images/ui/menu_burger_to_x_ani_5.png");}
    81%  {content:url("../../images/ui/menu_burger_to_x_ani_6.png");}
    90%  {content:url("../../images/ui/menu_burger_to_x_ani_7.png");}
    100% {content:url("../../images/ui/menu_burger_to_x_ani_8.png");}
}

.navbar {
    background: rgb(0,116,255);
    background: linear-gradient(-90deg, rgba(0,116,255,1) 0%, rgba(0,70,153,1) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-navbar-normal);
    position: sticky;
    top: 0;
    z-index: 999;
}

.navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
}

.navbar-logo {
    color: #282828;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: var(--font-size-navbar-normal);
    display: flex;
    align-items: center;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-gap: 5px;
    list-style: none;
    text-align: center;
    width: 85vw;
    justify-content: center;
    margin-right: 2rem;
}

.nav-item {
    height: 80px;
}

.nav-links {
    color: #ffffff;
    display: block;
    text-decoration: none;
    padding: 2rem 1rem 0 1rem;
    height: 100%;
    font-size: var(--font-size-navbar-normal);
    font-weight: bold;
}

.nav-links:hover {
    color: var(--overlay-font-color-hover);
    transition: all 0.2s ease-out;
}

.nav-links::after {
    content: "";
    display: block;
    height: 0.1em;
    width: 0;
    background-color: var(--overlay-font-color-hover);
    transition: all ease-in-out 300ms;
    position: absolute;
    bottom: 0;
    opacity: 0;
}

.nav-links:hover::after {
    width: 4em;
    opacity: 100%;
}

.nav-links-mobile {
    display: none;
    font-size: var(--font-size-navbar-large);
}

.menu-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -120%;
        opacity: 0;
        transition: all 0.5s ease;
        justify-content: flex-start;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 0.8rem;
        width: 100%;
        display: table;
        font-size: var(--font-size-navbar-large);
    }

    .nav-links:hover {
        background-color: #fff;
        color: #242424;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: var(--font-size-navbar-normal);
        cursor: pointer;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: var(--font-size-navbar-normal);
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
    }
}