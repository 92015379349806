.privacy-choice-box-container {
    width: 100%;
    font-size: var(--font-size-standard-normal);
    font-family: var(--font-family), sans-serif;
    font-weight: normal;
}

.privacy-choice-box-button-container {
    display: flex;
    justify-content: flex-end;
}

.privacy-choice-box-required-text {
    font-weight: bolder;
    margin: 15px 0;
}