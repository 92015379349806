.hashtag-wrap {
    margin: 0 5px;
    width: 240px;
    color: var(--font-color);
}

.hashtag-icon-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 3px;
    width: 100%;
}

.hashtag-text {
    font-size: 32px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}
.hashtag-social-hash {
    color: var(--font-color);
    font-weight: bold;
}
.hashtag-social-icon {
    margin: 0 2px;
    width: 52px;
    height: 52px;
    cursor: pointer;
}
.hashtag-twitter {
    background: url("../../../images/social/twitter_72.png") no-repeat;
    background-size: contain;
    background-position-y: 4px;
}

.hashtag-facebook {
    background: url("../../../images/social/facebook_72.png") no-repeat;
    background-size: contain;
}

.hashtag-instagram {
    background: url("../../../images/social/instagram_72.png") no-repeat;
    background-size: contain;
}