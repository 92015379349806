.privacy-banner-container {
    color: var(--overlay-font-color);
    font-size: var(--font-size-standard-normal);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2147483645;
    box-sizing: border-box;
    width: 100%;
    background-color: rgb(30, 30, 30);
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.privacy-banner-text-container a {
    margin: 5px;
    color:  var(--link-font-color-hover);
    text-decoration: none;
}

.privacy-banner-link-container {
    display: flex;
    flex-direction: row;
}

.privacy-banner-text-container a:hover {
    color: var(--overlay-font-color-hover);
    text-decoration: none;
}

.privacy-banner-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.privacy-banner-button-container .btn {
    margin: 5px;
}

@media screen and (max-width: 650px) {
    .privacy-banner-button-container {
        flex-direction: column;
    }
}