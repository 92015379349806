.cards {
    background: var(--background-color);
}

.cards_container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
}

.cards_wrapper {
    position: relative;
    margin: 30px 0 25px;
    width: 95%;
}

.cards_items {
    margin-bottom: 24px;
}

.cards_item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
}

.cards_item_link {
    display: flex;
    flex-flow: column;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    color: var(--link-font-color);
}

.cards_item_link:hover {
    color: var(--link-font-color-hover);
    box-shadow: 0 0 10px 0 var(--main-color);
}

.cards_image_wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
}

.cards_image_wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: var(--font-size-cards-normal);
    font-weight: 700;
    color: var(--background-color);
    background-color: var(--main-color-transparent);
    box-sizing: border-box;
}

.cards_image_wrap:hover::after {
    font-size: var(--font-size-cards-hover);
    background-color: var(--main-color);
}

.cards_item_image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    transition: all 0.2s linear;
}

.cards_item_image:hover {
    transform: scale(1.1);
}

.cards_item_info {
    border-top: 3px solid var(--main-color);
    padding: 12px;
}

.cards_item_text {
    font-size: var(--font-size-cards-normal);
    line-height: 24px;
}

@media only screen and (min-width: 1200px) {
    .content_blog_container {
        width: 84%;
    }
}

@media only screen and (min-width: 960px) {
    .cards_items {
        display: flex;
    }
}

@media only screen and (max-width: 960px) {
    .cards_item {
        margin: 0 5px 2rem;
    }

    .cards_wrapper > ul {
        margin: 2px;
    }

    .cards_wrapper {
        width: 100%;
    }
}