.event-title-wrap {
    padding: 18px;
    font-size: var(--font-size-h1-normal);
    font-style: oblique;
}

.event-title, .event-title > input {
    font-size: var(--font-size-h1-normal);
    font-style: oblique;
}

.event-location {
    padding: 5px 15px;
    font-size: var(--font-size-standard-normal);
    font-style: italic;
    color: var(--font-color);
    background: var(--reduced-color);
    height: 1.8em;
    margin-bottom: 8px;
}

.event-text {
    padding: 18px;
}

.event-date-wrap {
    padding: 5px 15px;
    font-size: var(--font-size-standard-normal);
    font-style: italic;
    color: var(--overlay-font-color);
    background: var(--main-color);
    height: 1.8em;
}

.event-time-left {
    text-align: left;
    float: right;
}

.event-date {
    text-align: left;
    float: left;
}

.event-wrap {
    font-size: var(--font-size-standard-normal);
    margin: 20px auto;
    width: 90%;
    max-width: 600px;
}
.event_title_image {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 336px;
    object-fit: cover;
}

.event_title_image_source {
    text-align: right;
    font-size: var(--font-size-anotations-normal);
    margin: 0 18px;
}

@media screen and (max-width: 960px) {
    .event-time-left {
        text-align: center;
        float: none;
    }

    .event-date {
        text-align: center;
        float: none;
    }

    .event-date-wrap {
        height: auto;
    }
}