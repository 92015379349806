.textArea-display {
    white-space: pre-wrap;
}

.editable-text-field {
    display: inline;
}

textarea {
    width: 100%;
    min-height: 120px;
}

