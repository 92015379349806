.events-container {
    max-width: 1032px;
    margin: 12px auto;
    width: 90%;
    font-size: var(--font-size-standard-normal);
    padding: 18px 45px 18px 18px;
    display: flex;
    justify-content: left;
}

.events-time-left {
    background-color: var(--main-color);
    color: var(--overlay-font-color);
    padding: 10px;
    display: grid;
    place-items: center;
    width: 100px;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
}

.events-text {
    font-weight: bold;
    font-size: var(--font-size-h1-normal);
}

.events-date {
    color: var(--font-color-reduced);
}

.events-location {
    color: var(--font-color-reduced);
}
.events-left {
    margin: 5px 10px;
}
.event_item_link:link {
    color: var(--link-font-color);
    text-decoration: none;
}
.event_item_link:hover {
    color: var(--link-font-color-hover);
    text-decoration: none;
}
.event_item_link:visited {
    color: var(--link-font-color-visted);
    text-decoration: none;
}