.tweet-wrap {
    min-height: 250px;
}

.tweet-show-button {
    text-align: center;
    cursor: pointer;
}

.tweet-show-button-logo {
    height: 120px;
    width: 120px;
    background: url("../../../images/social/twitter.png") no-repeat;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    background-size: contain;
}

.tweet-show-external-content-info {
    margin-top: 12px;
    text-align: center;
}

.tweet-show-external-content-wrap {
    min-height: 250px;
    display: inline-block;
    font-family: var(--font-family), sans-serif;
    font-size: var(--font-size-standard-normal);
    font-weight: bold;
    line-height: 16px;
    border-color: #eee #ddd #bbb;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    margin: 10px 5px;
    padding: 0 16px 16px 16px;
    width: 100%;
}