.welcome-container {
    background: #fff;
    padding: 12px;
    text-align: center;
}

.welcome-text-container {
    font-size: var(--font-size-standard-normal);
    max-width: 1120px;
    margin: 12px auto;
    width: 70%;
}