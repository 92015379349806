.article-image-container img {
    width: 100%;
    max-width: 100%;
    height: 400px;
    object-fit: scale-down;
}

.article-image-source {
    text-align: right;
    font-size: var(--font-size-anotations-normal);
    margin: 0 18px;
}