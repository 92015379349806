.option_toggle_label {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.option_toggle_label input {
    opacity: 0;
    width: 0;
    height: 0;
}

.option_toggle_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.option_toggle_slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

.option_toggle_label input:checked + .option_toggle_slider {
    background-color: var(--button-background-color-hover);
}

.option_toggle_label input:focus + .option_toggle_slider {
    box-shadow: 0 0 1px var(--button-background-color-hover);
}

.option_toggle_label input:checked + .option_toggle_slider:before {
    transform: translateX(26px);
}