.privacy-choice-item-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.privacy-choice-item-container {
    display: flex;
    width: 100%;
    padding: 3px;
}

.privacy-choice-item-text-container {
    width: 80%;
}

.privacy-choice-item-toggle-container {
    width: 20%;
}

.privacy-choice-item-container-enabled {
    color: var(--font-color);
    background: none;
}

.privacy-choice-item-container-disabled {
    color: var(--font-color-reduced);
    background: #cccccc;
}
