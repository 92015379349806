.article-title-wrap {
    padding: 18px;
    font-size: var(--font-size-h1-normal);
    font-style: oblique;
}

.article-title,  .article-title > input {
    font-size: var(--font-size-h1-normal);
    font-style: oblique;
}

.article-text {
    padding: 18px;
}

.article-text > h2 {
    margin-top: 3px;
    margin-bottom: 10px;
    font-weight: bold;
}

.article-text > h3 {
    margin-top: 3px;
    margin-bottom: 10px;
    font-weight: bold;
}

.article-author-wrap {
    margin-top: 4px;
    font-size: var(--font-size-anotations-normal);
    font-style: italic;
    color: #0b3a5f;
}

.article-date-wrap {
    margin-bottom: 4px;
    font-size: var(--font-size-anotations-normal);
    font-style: italic;
    color: #8a8a8a;
}

.article-wrap {
    font-size: var(--font-size-standard-normal);
    margin: 20px auto;
    width: 90%;
    max-width: 600px;
}

.article_title_image {
    width: 100%;
    max-width: 100%;
    height: 336px;
    object-fit: scale-down;
}

.article_title_image_source {
    text-align: right;
    font-size: var(--font-size-anotations-normal);
    margin: 0 18px;
}
.article_video_image {
    width: 100%;
    max-width: 100%;
    height: 450px;
    display:inline-block;
}

.video-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    height: 70px;
    width: 70px;
    text-align: center;
    background: url("../../images/ui/video_play.svg");
    cursor: pointer;
    position:absolute;
    display:inline-block;
}

.video-play-button:hover {
    background: url("../../images/ui/video_play_hover.svg");
}

.video-play-external-content-info {
    position: absolute;
    top: 76%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background: var(--main-color);
    color: var(--overlay-font-color);
    border: 1px solid var(--background-color);
    border-radius: 5px;
    padding: 5px;
}

.video-play-external-content-info > a {
    color: var(--overlay-font-color);
}

.video-play-external-content-info > a:hover {
    color: var(--overlay-font-color-hover);
}

.article-video-wrap {
    height: 520px;
}
