.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    min-width: 8em;
}

.btn--primary {
    background-color: var(--button-background-color-hover);
    color: var(--button-background-color);
    border: 1px solid var(--button-background-color);
}

.btn--outline {
    background-color: #ffffff66;
    color: var(--button-background-color);
    padding: 8px 20px;
    border: 1px solid var(--button-background-color);
    transition: all 0.3s ease-out;
}

.btn--small {
    padding: 2px 5px;
    font-size: var(--font-size-button-small);
}

.btn--medium {
    padding: 8px 20px;
    font-size: var(--font-size-button-medium);
}

.btn--disabled {
    background-color: var(--button-background-color-diabled);
    border: none;
}

.btn--large {
    padding: 12px 26px;
    font-size: var(--font-size-button-large);
}

.btn--ok {
    background-color: #c9f695;
    margin-left: 4px;
}

.btn--cancel {
    background-color: #ef9487;
    margin-left: 4px;
}

.btn--medium:hover, .btn--large:hover {
    background: var(--button-background-color-hover);
    color: var(--button-background-color);
    transition: all 0.3s ease-out;
}
.btn--outline:hover {
    border: 1px solid var(--button-background-color);
}
.btn--primary:hover {
    background-color: var(--button-background-color);
    color: var(--button-background-color-hover);
    border: 1px solid var(--button-background-color-hover);
}

.btn--disabled:hover {
    background-color: var(--button-background-color-diabled);
    color: var(--button-background-color);
    border: none;
}