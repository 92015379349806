.literature_item {
    display: flex;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    color: black;
}
.literature_item_right {
    width: 100%;
    margin-left: 10px;
}

.literature_item_info {
    display: flex;
    justify-content: flex-end;
    font-style: italic;
    color: #2b2b2b;
    margin: 10px 5px;
}

.literature_item_info img {
    height: 20px;
    width: 20px;
    vertical-align:bottom;
    margin-right: 2px;
}

.literature_item_image {
    height: 100px;
    width: 75px;
    margin: 5px;
}

.literature_item_web_link {

}

.literature_item_text {
    margin-top: 10px;
    padding-right: 30px;
}

.literature_item_info_entry {
    margin: 5px;
}