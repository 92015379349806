.contact-container {
    background: #fff;
    text-align: center;
}

.contact-text-container {
    max-width: 1120px;
    margin: 12px auto;
    width: 70%;
    font-size: var(--font-size-standard-normal);
    padding: 18px 45px 18px 18px;
}