.article-quote {
    position: relative;
    font-size: var(--font-size-standard-normal);
    line-height: 30px;
    background: var(--qoute-background);
    border-left: 5px solid var(--main-color);
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
}

.article-quote:before {
    color: var(--main-color);
    font-style: italic;
    content: '"';
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.1em;
    vertical-align: -0.4em;
}

.article-quote blockquote p {
    display: inline;
}

.article-quote cite {
    display: block;
    text-align: right;
    font-size: var(--font-size-anotations-normal);
    line-height: 20px;
    margin-top: 10px;
    text-transform: uppercase;
    color: var(--font-color-reduced);
}
