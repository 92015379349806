.footer-container {
    background: rgb(0,116,255);
    background: linear-gradient(-90deg, rgba(0,116,255,1) 0%, rgba(0,70,153,1) 100%);
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    margin-bottom: 24px;
    padding: 24px;
    color: var(--overlay-font-color);
}

.footer-subscription > p {
    font-family: var(--font-family), sans-serif;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: var(--font-size-h1-normal);
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: var(--font-size-h2-normal);
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    font-size: var(--font-size-standard-normal);
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items > h2 {
    color: var(--overlay-font-color);
}

.footer-link-items a {
    color: var(--overlay-font-color);
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: var(--overlay-font-color-hover);
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-input::placeholder {
    color: #b1b1b1;
}

.social-icon-link {
    color: var(--overlay-font-color);
    font-size: var(--font-size-h1-normal);
}

.footer-title-section {
    max-width: 1000px;
    width: 100%;
}

.footer-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.footer-title-text-link {
    color: var(--overlay-font-color);
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: var(--font-size-h1-normal);
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.website-rights {
    color: var(--overlay-font-color);
    margin-bottom: 16px;
}

.deploy-info-container {
    background-color: var(--background-color);
    border-radius: 10px;
    font-size: var(--font-size-anotations-normal);
    text-align: center;
    font-weight: bold;
}

.deploy-info-online {
    color: #02bf00;
    margin: 8px;
}

.deploy-info-offline {
    color: #ff0000;
    margin: 8px;
}

.footer-design-splitter {
    width: 100%;
    height: 120px;
    background-color: var(--background-color);
}
.footer-design-splitter-left {
    width: 120px;
    height: 120px;
    content:url("../../images/ui/gk_sign_flowers.svg");
    position: absolute;
    left: 5%;
}

.footer-design-splitter-right {
    width: 120px;
    height: 132px;
    content:url("../../images/ui/family.svg");
    position: absolute;
    right: 5%;
}

.footer-design-splitter-bottom {
    width: 100%;
    height: 130px;
    background-image:url("../../images/ui/grass.svg");
    background-position: bottom;
    background-size: 300px 40px;
    background-repeat: repeat-x;
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }

    .footer-input {
        width: 100%;
    }

    .btn {
        width: 100%;
    }

    .footer-link-wrapper {
        flex-direction: column;
    }

    .footer-title-wrap {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
}