:root {
  /* if main-color gets changed -> update all occurrences of theme_color and theme-color as well */
  --main-color: #0074FF;
  --main-color-transparent: #0074FFAA;
  --reduced-color: #e3e3e3;
  --background-color: #ffffff;
  --font-color: #000000;
  --font-color-reduced: #888888;
  --link-font-color: #1f1f1f;
  --link-font-color-visted: #3b3b3b;
  --link-font-color-hover: #0074FF;
  --overlay-font-color: #ffffff;
  --overlay-font-color-hover: #00FFA7;
  --qoute-background: #f3f7ff;
  --button-font-color: #000000;
  --button-font-color-hover: #ffffff;
  --button-border-color: #0074FF;
  --button-border-color-hover: #ffffff;
  --button-background-color: #ffffff;
  --button-background-color-hover: #0074FF;
  --button-background-color-diabled: #888888;

  /* has to be downloaded in index.html! */
  --font-family: 'Slabo';

  --font-size-standard-normal: 1.2rem;
  --font-size-standard-mobile: 1rem;

  --font-size-h1-normal: 2rem;
  --font-size-h1-mobile: 1.5rem;

  --font-size-h2-normal: 1.8rem;
  --font-size-h2-mobile: 1.2rem;

  --font-size-h3-normal: 1.6rem;
  --font-size-h3-mobile: 1.1rem;

  --font-size-anotations-normal: 0.8rem;
  --font-size-anotations-mobile: 0.7rem;

  --font-size-hero-headline: 3rem;
  --font-size-hero-subline: 2rem;

  --font-size-navbar-normal: 1.2rem;
  --font-size-navbar-large: 2rem;

  --font-size-button-small: 1rem;
  --font-size-button-medium: 1.2rem;
  --font-size-button-large: 1.4rem;

  --font-size-cards-normal: 1rem;
  --font-size-cards-hover: 1.1rem;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--font-family), sans-serif;
}

ul {
  margin: 5px 25px;
}

h1 {
  font-size: var(--font-size-h1-normal);
  text-align: center;
}

h2 {
  font-size: var(--font-size-h2-normal);
  margin: 15px 0;
}

h3 {
  font-size: var(--font-size-h3-normal);
  margin: 15px 0;
}

.content-title {
  font-size: var(--font-size-h1-normal);
  margin: 16px;
  text-align: center;
}

.content-sub-title {
  text-align: center;
  margin: 4px;
}

.content-box {
  border-bottom: 1px solid var(--main-color);
}

.App-logo {
  height: 40vmin;
  width: 40vmin;
  pointer-events: none;
  position: absolute;
  animation-name: aniFadeOut;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #1d1d1d;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.App-logo-part {
  background-repeat: no-repeat;
  height: 18vmin;
  position: absolute;
}

.content {
  opacity: 0.0;
  animation-name: aniFadeIn;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.content-area {
  min-height: 100vh;
}

.content-container {
  width: 90%;
  max-width: 600px;
  margin: 24px auto;
}

@media (prefers-reduced-motion: no-preference) {

  #LogoWorld {
    background-image: url("images/ui/logo_world.gif");
    background-size: 25vmin;
    background-position-x: 50%;
    background-position-y: 50%;
    z-index: 0;
    animation-name: aniLogoWorld;
    animation-duration: 2s;
    height: 40vmin;
    width: 40vmin;
  }

  #LogoRing {
    background-image: url("images/ui/logo_ring.svg");
    background-size: 40vmin;
    background-position-x: 1vmin;
    background-position-y: 0;
    z-index: 1;
    animation-name: aniLogoRing;
    animation-duration: 2s;
    height: 40vmin;
    width: 40vmin;
  }

  #LogoTextTop {
    background-image: url("images/ui/logo_text_top.svg");
    background-size: 40vmin;
    background-position-x: 6.5vmin;
    background-position-y: 2.5vmin;
    z-index: 2;
    animation-name: aniLogoTextTop;
    animation-duration: 2s;
    height: 40vmin;
    width: 40vmin;
  }

  #LogoTextBottom {
    background-image: url("images/ui/logo_text_bottom.svg");
    background-size: 40vmin;
    background-position-x: 3vmin;
    background-position-y: 21vmin;
    z-index: 3;
    animation-name: aniLogoTextBottom;
    animation-duration: 2s;
    height: 40vmin;
    width: 40vmin;
  }

  #LogoLocationMarker {
    background-image: url("images/ui/logo_location_marker.svg");
    background-size: 40vmin;
    background-position-x: 22.7vmin;
    background-position-y: 12vmin;
    z-index: 4;
    animation-name: aniLogoLocationMarker;
    animation-duration: 1s;
    height: 40vmin;
    width: 40vmin;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes aniLogoWorld {
  0%   {opacity: 0.0;}
  25%  {opacity: 1.0;}
  50%  {opacity: 1.0;}
  75%  {opacity: 1.0;}
  100% {opacity: 1.0;}
}

@keyframes aniLogoRing {
  0%   {opacity: 0.0;}
  25%  {opacity: 0.0;}
  50%  {opacity: 0.0;}
  75%  {opacity: 0.0;}
  100% {opacity: 1.0;}
}

@keyframes aniLogoTextTop {
  0%   {opacity: 0.0;}
  25%  {opacity: 0.0;}
  50%  {opacity: 0.5;}
  75%  {opacity: 1.0;}
  100% {opacity: 1.0;}
}

@keyframes aniLogoTextBottom {
  0%   {opacity: 0.0;}
  25%  {opacity: 0.0;}
  50%  {opacity: 0.0;}
  75%  {opacity: 0.0;}
  100% {opacity: 1.0;}
}
@keyframes aniLogoLocationMarker {
  0%   {opacity: 0.0;  background-position-y: 5vmin;}
  100% { background-position-y: 12vmin;}
}

@keyframes aniFadeIn {
  0%   {opacity: 0.0;}
  100% {opacity: 1.0;}
}
@keyframes aniFadeOut {
  0%   {opacity: 1.0;}
  100% {opacity: 0.0;}
}