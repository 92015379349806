.hero-background {
    object-fit: cover;
    width: 100%;
    height: 45vh;
    position: absolute;
    z-index: -1;
}

.hero-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image:
            url("../../images/ui/large_globe.svg"),
            linear-gradient(-90deg, rgba(0, 116, 255, 1) 0%, rgba(0,70,153,1) 100%);
    background-repeat: no-repeat;
    background-position-x: right;
}

.hero-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 5px;
    padding: 15px;
    color: var(--overlay-font-color);
    text-shadow: rgba(0, 0, 0, 0.61) 1px 0 10px;
}

.hero-btns {
    margin-bottom: 6px;
}

.hero-btns .btn {
    margin: 6px;
}

.hero-text-container > h1 {
    margin: 15px;
    font-size: var(--font-size-hero-headline);
    font-family: var(--font-family), sans-serif;
}

.hero-text-container > h2 {
    color: var(--overlay-font-color-hover);
    font-size: var(--font-size-hero-subline);
    font-family: var(--font-family), sans-serif;
}

.hero-splitter {
    background-color: var(--background-color);
}

.hero-splitter-design {
    background-color: var(--main-color);
    width: 100%;
    height: 18px;
    border-bottom-left-radius: 50% 30%;
    border-bottom-right-radius: 50% 30%;
}

.hero-hashtags {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: right;
    align-self: flex-start;
    margin: 10px 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.hero-extended-title {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    background-color: var(--background-color);
    height: 60px;
    font-size: 34px;
    padding: 12px 0;
    text-align: center;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


@media screen and (max-width: 960px){
    .hero-container > h1 {
        font-size: 32px;
    }

    .hero-container > p {
        font-size: 14px;
    }
}

@media screen and (max-width: 768px){
    .hero-extended-title {
        font-size: 26px;
    }

    .hero-container > h1 {
        font-size: 32px;
    }

    .hero-container > p {
        font-size: 12px;
    }

    .btn-main {
        text-decoration: none;
    }
    .btn {
        width: 100%;
    }

    .hero-btns {
        width: 90%;
    }
}
